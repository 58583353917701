import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Link from '~components/Link';
import BannerBagsImage from '~images/black-friday/black-friday-banner-bags.svg';
import { useGlobalStore } from '~context/GlobalContext/GlobalContextProvider';
import useCountdown from '../useCountdown';
import { BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH } from '../constants';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    bottom: '0%',
    left: '0%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '40px',
    backgroundColor: '#000000',
    padding: '4px 10px 12px 20px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
      gap: 0
    }
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start'
    }
  },
  imageTimerArea: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    [theme.breakpoints.down('xs')]: {
      gap: '10px'
    }
  },
  image: {
    maxHeight: '30px'
  },
  title: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '140%',
    marginTop: 0,
    marginBottom: 0,
    '& span': {
      color: '#79B0FF'
    }
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 28
  },
  timerArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 7,
    '& > p': {
      marginTop: 0,
      marginBottom: 0,
      alignSelf: 'flex-start',
      color: '#FFFFFF',
      fontFamily: 'Inter',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '140%',
      [theme.breakpoints.down('xs')]: {
        marginTop: 2,
        fontSize: '14px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      columnGap: '4px'
    }
  },
  time: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > h6': {
      marginTop: 0,
      marginBottom: 0,
      color: '#FFFFFF',
      fontFamily: 'Inter',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '140%',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px'
      }
    },
    '& > p': {
      marginTop: -5,
      marginBottom: 0,
      color: '#FFFFFF',
      fontFamily: 'Inter',
      fontSize: '9px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '140%',
      [theme.breakpoints.down('xs')]: {
        marginTop: -2,
        fontSize: '8px',
        fontWeight: 400
      }
    }
  },
  ctaButton: {
    padding: '9px 15px',
    textTransform: 'none',
    letterSpacing: 0,
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '160%',
    textAlign: 'center',
    borderRadius: '40px',
    color: '#FFFFFF',
    backgroundColor: '#278ae7',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#278ae7',
      textDecoration: 'none'
    }
  }
}));

const setIntercomLauncherVerticalPadding = paddingY => {
  const intercomElements = document.getElementsByClassName('intercom-lightweight-app');
  // check if intercom app available in the dom
  if (intercomElements && intercomElements[0]) {
    const intercomLaunchers = intercomElements[0].getElementsByClassName(
      'intercom-lightweight-app-launcher'
    );
    if (intercomLaunchers && intercomLaunchers[0]) {
      intercomLaunchers[0].style.bottom = paddingY;
      return true;
    }
  }

  // when user click on intercom launcher icon, it changes the launcher button to use the button inside the iframe
  const intercomUpdatedLauncher = document.getElementsByClassName('intercom-dfosxs');
  if (intercomUpdatedLauncher && intercomUpdatedLauncher[0]) {
    intercomUpdatedLauncher[0].style.bottom = paddingY;
    return true;
  }

  return false;
};

/**
 * This function is to try and update the position of intercom launcher position
 * By default intercom doesn't provide a way to determine whether the launcher is loaded or not.
 * Also intercom does not support setting vertical_padding in mobile browsers
 * https://developers.intercom.com/installing-intercom/web/attributes-objects/
 *
 * What this function would do is check whether intercom launcher available in the dom.
 * If not available it retries again in 1 second, until a maximum of 10 seconds.
 *
 * NOTE: WE SHOULD NOT USE THESE KIND OF HACKS. THIS IS SOLELY FOR THE SAKE OF BLACK FRIDAY
 * @param {*} paddingY
 */
const tryUpdateIntercomLauncherPosition = paddingY => {
  let inIntercomUpdated = false;
  let tryCount = 0;
  const interval = setInterval(() => {
    inIntercomUpdated = setIntercomLauncherVerticalPadding(paddingY);
    tryCount += 1;
    if (inIntercomUpdated || tryCount === 10) {
      clearInterval(interval);
    }
  }, 1000);
};

const MobileBanner = ({ onBannerClick }) => {
  const classes = useStyles();
  const { days, hours, minutes, seconds } = useCountdown();

  const {
    state: { intercomInitialized, intercomMessageOpen }
  } = useGlobalStore();

  useEffect(() => {
    if (intercomInitialized) {
      tryUpdateIntercomLauncherPosition('70px');
    }
    return () => {
      tryUpdateIntercomLauncherPosition('');
    };
  }, [intercomInitialized, intercomMessageOpen]);

  return (
    <Link to={BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH} underline="none" onClick={onBannerClick}>
      <div className={classes.container}>
        <div className={classes.contentArea}>
          <div className={classes.imageTimerArea}>
            <img className={classes.image} src={BannerBagsImage} alt="black friday offer" />
            <div className={classes.timerArea}>
              <div className={classes.time}>
                <h6>{String(days).padStart(2, '0')}</h6>
                <p>Days</p>
              </div>
              <p>:</p>
              <div className={classes.time}>
                <h6>{String(hours).padStart(2, '0')}</h6>
                <p>Hours</p>
              </div>
              <p>:</p>
              <div className={classes.time}>
                <h6>{String(minutes).padStart(2, '0')}</h6>
                <p>Minutes</p>
              </div>
              <p>:</p>
              <div className={classes.time}>
                <h6>{String(seconds).padStart(2, '0')}</h6>
                <p>Seconds</p>
              </div>
            </div>
          </div>

          <p className={classes.title}>
            Black Friday Sale <span>$3 for 3 months</span>
          </p>
        </div>
        <div className={classes.actionArea}>
          <Button
            component={Link}
            className={classes.ctaButton}
            to={BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH}
          >
            Get Offer Now
          </Button>
        </div>
      </div>
    </Link>
  );
};

MobileBanner.propTypes = {
  onBannerClick: PropTypes.func.isRequired
};

MobileBanner.defaultProps = {};

export default MobileBanner;
