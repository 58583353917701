import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import BannerBagsImage from '~images/black-friday/black-friday-banner-bags.svg';
import Link from '~components/Link';
import useCountdown from '../useCountdown';
import { BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH } from '../constants';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    bottom: '12px',
    left: '0%',
    width: '100%',
    zIndex: 460,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentArea: {
    position: 'relative'
  },
  root: {
    backgroundColor: '#000000',
    boxShadow: '4px 0px 3px 2px rgba(0, 0, 0, 0.1)',
    padding: '8px 53px 8px 95px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '54px',
    borderRadius: 60,
    gap: '53px'
  },
  image: {
    position: 'absolute',
    left: -30,
    top: 0,
    bottom: 0
  },
  title: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '140%',
    marginTop: 0,
    marginBottom: 0,
    minWidth: '441px',
    '& span': {
      color: '#79B0FF'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset'
    }
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 28
  },
  timerArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 7,
    '& > p': {
      marginTop: 0,
      marginBottom: 0,
      alignSelf: 'flex-start',
      color: '#FFFFFF',
      fontFamily: 'Inter',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '140%'
    }
  },
  time: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > h6': {
      marginTop: 0,
      marginBottom: 0,
      color: '#FFFFFF',
      fontFamily: 'Inter',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '140%'
    },
    '& > p': {
      marginTop: -5,
      marginBottom: 0,
      color: '#FFFFFF',
      fontFamily: 'Inter',
      fontSize: '9px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '140%'
    }
  },
  ctaButton: {
    padding: '9px 15px',
    textTransform: 'none',
    letterSpacing: 0,
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '160%',
    textAlign: 'center',
    borderRadius: '40px',
    color: '#FFFFFF',
    backgroundColor: '#1775FB',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#1775FB',
      textDecoration: 'none'
    }
  },
  closeBtnArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '10px',
    zIndex: 500
  },
  closeBtn: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  closeIcon: {
    color: '#FFFFFF',
    fontSize: '18px'
  }
}));

const DesktopBanner = ({ handleBannerClose, onBannerClick }) => {
  const classes = useStyles();
  const { days, hours, minutes, seconds } = useCountdown();

  return (
    <div className={classes.container}>
      <div className={classes.contentArea}>
        <Link to={BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH} underline="none" onClick={onBannerClick}>
          <div className={classes.root}>
            <img className={classes.image} src={BannerBagsImage} alt="black friday offer" />
            <p className={classes.title}>
              {days === 0 ? (
                <>
                  Final hours of Black Friday Sale: Get <span>$3 for 3 months</span>
                </>
              ) : (
                <>
                  Black Friday Sale: Get A Premium Subscription <span>$3 for 3 months</span>
                </>
              )}
            </p>
            <div className={classes.actionArea}>
              <div className={classes.timerArea}>
                <div className={classes.time}>
                  <h6>{String(days).padStart(2, '0')}</h6>
                  <p>Days</p>
                </div>
                <p>:</p>
                <div className={classes.time}>
                  <h6>{String(hours).padStart(2, '0')}</h6>
                  <p>Hours</p>
                </div>
                <p>:</p>
                <div className={classes.time}>
                  <h6>{String(minutes).padStart(2, '0')}</h6>
                  <p>Minutes</p>
                </div>
                <p>:</p>
                <div className={classes.time}>
                  <h6>{String(seconds).padStart(2, '0')}</h6>
                  <p>Seconds</p>
                </div>
              </div>
              <Button
                component={Link}
                className={classes.ctaButton}
                to={BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH}
              >
                Redeem Offer
              </Button>
            </div>
          </div>
        </Link>
        <div className={classes.closeBtnArea}>
          <IconButton
            aria-label="close"
            onClick={handleBannerClose}
            classes={{
              root: classes.closeBtn
            }}
            disableRipple
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

DesktopBanner.propTypes = {
  handleBannerClose: PropTypes.func.isRequired,
  onBannerClick: PropTypes.func.isRequired
};

DesktopBanner.defaultProps = {};

export default DesktopBanner;
