import React from 'react';
import PropTypes from 'prop-types';
import { useCookie } from '@use-hook/use-cookie';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { useGlobalStore } from '~context/GlobalContext/GlobalContextProvider';
import { pushDataLayerEvent } from '~utils/data-layer';
import DesktopBanner from './DesktopBanner';
import MobileBanner from './MobileBanner';
import useTargetAudience from '../useTargetAudience';
import { BLACK_FRIDAY_ANALYTICS_EVENT } from '../constants';

const BLACK_FRIDAY_BANNER_COOKIE_KEY = 'hide-black-friday-banner';

const BlackFridayBanner = ({ disabled }) => {
  const [closed, setClosed] = useCookie(BLACK_FRIDAY_BANNER_COOKIE_KEY, false);
  const isBlackFridayTargetAudience = useTargetAudience();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const {
    state: { isSpotifyPlayerOpen }
  } = useGlobalStore();

  const handleBannerClose = e => {
    setClosed(true);
    e.stopPropagation();
  };

  const onBannerClick = () => {
    // adding analytics with ga4 custom event since utm parameters are not working with same site navigation
    pushDataLayerEvent(BLACK_FRIDAY_ANALYTICS_EVENT, { source: 'banner' });
  };

  const isBannerHidden = disabled || closed || isSpotifyPlayerOpen || !isBlackFridayTargetAudience;

  // hide banner when the banner is hidden
  if (isBannerHidden) {
    return false;
  }

  if (isMobile) {
    return <MobileBanner handleBannerClose={handleBannerClose} onBannerClick={onBannerClick} />;
  }

  return <DesktopBanner handleBannerClose={handleBannerClose} onBannerClick={onBannerClick} />;
};

BlackFridayBanner.propTypes = {
  disabled: PropTypes.bool
};

BlackFridayBanner.defaultProps = {
  disabled: false
};

export default BlackFridayBanner;
