import { useEffect, useState } from 'react';
import { BLACK_FRIDAY_OFFER_END_EPOCH } from './constants';

const getReturnValues = countDown => {
  if (countDown < 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

/**
 * Hook to get the count down till black friday
 * @returns {Object} { days, hours, minutes, seconds }
 */
const useCountdown = () => {
  const [countDown, setCountDown] = useState(BLACK_FRIDAY_OFFER_END_EPOCH - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(BLACK_FRIDAY_OFFER_END_EPOCH - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return getReturnValues(countDown);
};

export default useCountdown;
